import React from "react"
import * as styles from "./styles.module.scss"
import Container from "../Container/Container"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      yousuf: file(relativePath: { eq: "Yousuf.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      faiz: file(relativePath: { eq: "Faiz.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      fawaz: file(relativePath: { eq: "Fawaz.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Container>
      <section className={styles.aboutSection}>
        <div className={styles.aboutHeading}>
          <h3 className={styles.aboutHeadingSub}>Our Leader</h3>
          <h1 className={styles.aboutHeadingMain}>
            Meet the minds shaping an <span>Industry</span>
          </h1>
        </div>

        <div className={styles.aboutDetails}>
          <div className={styles.aboutImage}>
            <Img
              className={styles.heroImageRef}
              fluid={data.yousuf.childImageSharp.fluid}
              alt="Yousuf"
            />
          </div>

          <div className={styles.aboutText}>
            <h1 className={styles.aboutTextHeading}>UK YOOSUF</h1>
            <h3 className={styles.aboutTextHeadingSub}>Chairman, UK Group</h3>
            <p className={styles.aboutTextPara}>
              UK Yoosuf is one of the great minds produced by Kasargod, a
              district known for hosting diverse cultural and linguistic groups.
              <br />
              <br />
              A businessman by profession, UK Yoosuf gained popularity with his
              notable philanthropy, countless social services, and his valuable
              contribution in the fields of arts and culture, Thus becoming the
              pride of the state.
              <br />
              <br />
              UK Yoosuf always believed in traveling back to the roots and
              tirelessly working for the betterment of the state that nurtured
              him. Uk Yoosuf's determination to realize his vision along with a
              focused mindset and hard work led him to establish his first
              business venture at the age of 18, followed by being the owner of
              50 trucks at the age of 23 and finally becoming the founder of the
              prestigious enterprise UK traders.
            </p>
          </div>
        </div>

        <div className={styles.team}>
          <div className={styles.teamMember}>
            <div className={styles.teamImage}>
              <Img
                className={styles.teamFaiz}
                fluid={data.faiz.childImageSharp.fluid}
                alt="Faiz"
              />
            </div>

            <h1 className={styles.teamMemberHeading}>FAIZ UK</h1>
            <h3 className={styles.teamMemberHeadingSub}>Managing Director</h3>
          </div>
          <div className={styles.teamMember}>
            <div className={styles.teamImage}>
              <Img
                className={styles.teamFawaz}
                fluid={data.fawaz.childImageSharp.fluid}
                alt="Fawaz"
              />
            </div>

            <h1 className={styles.teamMemberHeading}>FAWAZ UK</h1>
            <h3 className={styles.teamMemberHeadingSub}>Managing Director</h3>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default About
