// extracted by mini-css-extract-plugin
export var aboutDetails = "styles-module--aboutDetails--e8d70";
export var aboutHeading = "styles-module--aboutHeading--50de7";
export var aboutHeadingMain = "styles-module--aboutHeadingMain--5d63d";
export var aboutHeadingSub = "styles-module--aboutHeadingSub--f546f";
export var aboutImage = "styles-module--aboutImage--11be2";
export var aboutSection = "styles-module--aboutSection--4d66f";
export var aboutText = "styles-module--aboutText--45e94";
export var aboutTextHeading = "styles-module--aboutTextHeading--a55d4";
export var aboutTextHeadingSub = "styles-module--aboutTextHeadingSub--9cffe";
export var aboutTextPara = "styles-module--aboutTextPara--4c497";
export var heroImageRef = "styles-module--heroImageRef--a7de5";
export var team = "styles-module--team--adf13";
export var teamFaiz = "styles-module--teamFaiz--3e9be";
export var teamFawaz = "styles-module--teamFawaz--46991";
export var teamImage = "styles-module--teamImage--2d42f";
export var teamMember = "styles-module--teamMember--674f3";
export var teamMemberHeading = "styles-module--teamMemberHeading--bafd3";
export var teamMemberHeadingSub = "styles-module--teamMemberHeadingSub--0c864";