import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import AboutPage from "../components/AboutPage/About"

const About = () => (
  <Layout>
    <Seo title="About" />
    <AboutPage />
  </Layout>
)

export default About
